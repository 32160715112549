@import '@groundsure/gs-mapper/dist/mapper.css';

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

  * {
    @apply font-sans;
    scroll-behavior: smooth;
  }

  html, body {
    @apply !overscroll-none;
  }

  input {
    @apply text-base;
  }

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #d1d5db transparent;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #d1d5db;
    border-radius: 100px;
    border: 0px solid #ffffff;
  }

  .scrollbar-hide::-webkit-scrollbar { 
    display: none; /* (Chrome, Safari and Opera)  */
  }

  .scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  #hubspot-messages-iframe-container:not(:has(.hs-shadow-container.active)) {
    margin-bottom: 4rem;
    margin-right: 1.5rem;
  }

  .control-panel-wrapper {
    @apply absolute flex flex-col bg-white z-10 max-lg:bottom-0 max-lg:w-full max-lg:rounded-tr-2xl lg:h-screen lg:top-0 left-0 transition-all duration-700 ease-in-out
  }

  /* Map scale control */
  .maplibregl-ctrl-bottom-right .maplibregl-ctrl {
    bottom: 5px;
  }
  @media (max-width: 1024px) {
    .maplibregl-ctrl-bottom-right .maplibregl-ctrl {
      bottom: -5px;
    }
  }
}

@font-face {
  font-display: auto;
  font-family: "MuseoSans-100";
  font-weight: 100;
  src: url("/fonts/MuseoSans-100.otf") format("opentype");
}

@font-face {
  font-display: auto;
  font-family: "MuseoSans-300";
  font-weight: 300;
  src: url("/fonts/MuseoSans-300.otf") format("opentype");
}

@font-face {
  font-display: auto;
  font-family: "MuseoSans-500";
  font-weight: 500;
  src: url("/fonts/MuseoSans-500.otf") format("opentype");
}

@font-face {
  font-display: auto;
  font-family: "MuseoSans-700";
  font-weight: 700;
  src: url("/fonts/MuseoSans-700.otf") format("opentype");
}

@font-face {
  font-display: auto;
  font-family: "MuseoSans-900";
  font-weight: 900;
  src: url("/fonts/MuseoSans-900.otf") format("opentype");
}

@layer components {
  /* Slider component */

  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    height: 0.25rem;
    background: #E5E7EB;
    border-radius: 0.5rem;
    background-image: linear-gradient(#002B54, #002B54);
    background-repeat: no-repeat;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background: #002B54;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  input[type="range"]::-webkit-slider-thumb:hover {
    box-shadow: rgba(102, 128, 152, 0.5) 0px 0px 0px 4px;
  }

  input[type="range"]::-webkit-slider-thumb:active {
    box-shadow: rgba(102, 128, 152, 0.5) 0px 0px 0px 4px;
  }

  /* Radio button */
  input[type='radio'] {
    appearance: none;
    -webkit-appearance: none;
    width: 1.25em;
    height: 1.25em;
    border-radius: 50%;
    outline: none;
    border: 2px solid #D1D5DB;
  }

  input[type='radio']:before {
      content: '';
      display: block;
      width: 60%;
      height: 60%;
      margin: 20% auto;
      border-radius: 50%;
  }

  input[type="radio"]:checked:before {
      background: #002B54;
  }

  input[type="radio"]:checked {
    border-color:#002B54;
  }

  .custom-list li::marker {
    color: #00D26E;
    @apply text-lg;
  }

  .clone-container > :first-child {
    box-shadow: 0 0 0 2px #00AFAA;
    border: none;
    animation: pulseShadow 2s infinite;
    margin: 0;
  }

  @keyframes pulseShadow {
      0% {
          box-shadow: 0 0 0 2px #00AFAA;
      }
      50% {
          box-shadow: 0 0 0 4px #99DFDD;
      }
      100% {
          box-shadow: 0 0 0 2px #00AFAA;
      }
  }
}
